<script>
	// https://www.w3schools.com/css/css_tooltip.asp
	export let useTooltipSlot = false;
	export let tooltipPosition = 'right';
	export let tooltipText = '';
	export let disabled = false;

	const getPosClass = (pos) => {
		switch (pos) {
			case 'right':
				return 'tooltip-right';
			case 'left':
				return 'tooltip-left';
			case 'top':
				return 'tooltip-top';
			case 'bottom':
				return 'tooltip-bottom';
			default:
				return 'tooltip-right';
		}
	};
</script>

<div class="tooltip">
	<slot name="element-with-tooltip" />
	{#if !disabled}
		<span class="tooltiptext text-sm {getPosClass(tooltipPosition)}">
			{#if useTooltipSlot}
				<slot name="tooltip-text" />
			{:else}
				{tooltipText}
			{/if}
		</span>
	{/if}
</div>

<style>
	.tooltip {
		position: relative;
		display: inline-block;
		/* border-bottom: 1px dotted black; */
	}

	.tooltip .tooltiptext {
		visibility: hidden;
		width: 200px;
		background-color: #eef1f4;
		color: #2a3240;
		text-align: center;
		padding: 0.5rem;

		border-width: 1px;
		border-color: #dee1e7;
		border-radius: 6px;

		/* Position the tooltip */
		position: absolute;
		z-index: 1;
	}

	.tooltip-right {
		top: -5px;
		left: 105%;
	}

	.tooltip-left {
		top: -5px;
		left: -105%;
	}

	.tooltip-top {
		top: -100px;
	}

	.tooltip-bottom {
		top: 100x;
	}

	.tooltip:hover .tooltiptext {
		visibility: visible;
	}
</style>
